<ng-container *ngIf="student">
  <div class="profile-pic">
    <ag-profile-image
      class="profile-pic"
      [id]="student.id"
      [size]="80"
    ></ag-profile-image>
    <span
      *ngIf="student.dynamicData?.rank"
      class="profile-pic__rank profile-pic__rank--{{
        student.dynamicData?.rank
      }}"
    >
      {{ student.dynamicData?.rank }}
    </span>
    <div class="profile-pic__plus" *ngIf="showPlus">
      <span>Plus</span>
    </div>
  </div>
  <div class="personal">
    <div class="personal__top-section">
      <div class="main-info">
        <div class="main-info__id">{{ student.frontId }}</div>
        <div class="main-info__name">
          {{
            student.dynamicData?.lastName + " " + student.dynamicData?.firstName
          }}
        </div>
        <div class="main-info__additional-list">
          <span class="main-info__additional">{{
            student.dynamicData?.phoneticLastName +
              " " +
              student.dynamicData?.phoneticFirstName
          }}</span>
          <span class="main-info__additional">
            {{ (student.dynamicData?.birthday | age) || "-" }}歳
          </span>
          <span class="main-info__additional">
            {{ student.dynamicData?.gender }}
          </span>
        </div>
        <div class="main-info__additional-list">
          <span
            *ngIf="student.dynamicData?.registrationStatus === '仮登録'"
            class="main-info__additional--red"
          >
            {{ student.dynamicData.registrationStatus }}
          </span>
        </div>
      </div>
      <div class="secondary">
        <div class="secondary__item">
          <span class="secondary__label">携帯TEL</span>
          <span class="secondary__value secondary__value--phone">
            {{
              student.dynamicData?.mobileTel?.tel
                ? student.dynamicData?.mobileTel?.tel
                : "-"
            }}
          </span>
          <span
            *ngIf="student?.dynamicData?.mobileTel?.telAvailable"
            class="secondary__icon"
            >不可</span
          >
        </div>
        <div class="secondary__item">
          <span class="secondary__label">自宅TEL</span>
          <span class="secondary__value secondary__value--phone">
            {{
              student.dynamicData?.houseTel?.tel
                ? student.dynamicData?.houseTel?.tel
                : "-"
            }}
          </span>
          <span
            *ngIf="student?.dynamicData?.houseTel?.telAvailable"
            class="secondary__icon"
            >不可</span
          >
        </div>
        <div class="secondary__item secondary__item--date">
          <span class="secondary__label">
            マイページ：
          </span>
          <span class="secondary__value secondary__value--date">
            {{ loggedInText }}
          </span>
          <span class="secondary__link" *ngIf="student.mypageLoggedInAt">
            <img class="secondary__img" src="/assets/icons/icn_url.svg" />
            URL
          </span>
        </div>
      </div>
      <div class="secondary">
        <div class="secondary__item">
          <span class="secondary__label">E-Mail</span>
          <span
            class="secondary__value secondary__value--email"
            [class.secondary__value--readonly]="
              student.dynamicData?.emailMain?.emailAvailable
            "
            [class.link]="
              student.dynamicData?.emailMain?.email &&
              !student.dynamicData?.emailMain?.emailAvailable
            "
            (click)="
              student.dynamicData?.emailMain?.email &&
              !student.dynamicData?.emailMain?.emailAvailable
                ? openMailSend(sendConfig)
                : ''
            "
          >
            {{
              student.dynamicData?.emailMain?.email
                ? student.dynamicData?.emailMain.email
                : "-"
            }}
          </span>
          <span
            *ngIf="student?.dynamicData?.emailMain?.emailAvailable"
            class="secondary__icon"
            >不可</span
          >
        </div>
        <div class="secondary__item">
          <span class="secondary__label">サブ</span>
          <span
            class="secondary__value secondary__value--email"
            [class.secondary__value--readonly]="
              student.dynamicData?.emailSub?.emailAvailable
            "
            [class.link]="
              student.dynamicData?.emailSub?.email &&
              !student.dynamicData?.emailSub?.emailAvailable
            "
            (click)="
              student.dynamicData?.emailSub?.email &&
              !student.dynamicData?.emailSub?.emailAvailable
                ? openMailSend(sendConfig)
                : ''
            "
          >
            {{
              student.dynamicData?.emailSub?.email
                ? student.dynamicData?.emailSub?.email
                : "-"
            }}
          </span>
          <span
            *ngIf="student?.dynamicData?.emailSub?.emailAvailable"
            class="secondary__icon"
            >不可</span
          >
        </div>
        <div class="secondary__item secondary__item--date">
          <span class="secondary__label">最終コンタクト： </span>
          <span class="secondary__value secondary__value--date">
            {{
              student.lastContactDate
                ? (student.lastContactDate | safeDate: "yyyy/MM/dd(E)")
                : "-"
            }}
          </span>
        </div>
      </div>
      <div class="student-icons">
        <a class="student-icons__mail">
          <div
            class="student-icons__icon"
            [class.link]="
              student.dynamicData?.emailMain?.email &&
              !student.dynamicData?.emailMain?.emailAvailable
            "
            (click)="
              student.dynamicData?.emailMain?.email &&
              !student.dynamicData?.emailMain?.emailAvailable
                ? openMailSend(sendConfig)
                : ''
            "
          >
            <img class="student-icons__img" src="/assets/images/mail.png" />
          </div>
          <div class="student-icons__text">
            
          </div>
        </a>
        <a
          class="student-icons__chat"
          [class.link]="
            student.dynamicData?.lineId
          "
          (click)="
            student.dynamicData?.lineId
              ? openChat()
              : ''
          "
        >
          <div class="student-icons__icon">
            <img class="student-icons__img" src="/assets/images/chat.png" />
          </div>
          <div class="student-icons__text">
            
          </div>
        </a>
        <div class="student-icons__calendar">
          <div class="student-icons__icon">
            <img class="student-icons__img" src="/assets/images/calendar.png" />
          </div>
          <div class="student-icons__text">
            
          </div>
        </div>
      </div>
    </div>
    <div class="personal__bottom-section">
      <div class="personal__badges">
        <div
          *ngIf="student?.dynamicData?.academicFieldType"
          class="badge"
          [class.badge--pink]="
            student.dynamicData.academicFieldType[0] === '文'
          "
          [class.badge--blue]="
            student.dynamicData.academicFieldType[0] === '理'
          "
        >
          {{ student.dynamicData.academicFieldType[0] }}
        </div>
        <div
          *ngIf="
            student?.dynamicData?.academicHistory1?.graduateYear &&
            student.dynamicData.academicHistory1.graduateType
          "
          class="badge badge--purple"
        >
          {{
            (
              +student.dynamicData.academicHistory1.graduateYear +
              (student.dynamicData.academicHistory1.graduateMonth > 3 ? 1 : 0) +
              ""
            ).substring(2)
          }}
          {{ student.dynamicData.academicHistory1.graduateType[0] }}
        </div>
      </div>
      <div class="institute">
        <div
          *ngIf="student.dynamicData?.universityLevel"
          class="institute__class"
        >
          {{ student.dynamicData.universityLevel[0] }}
        </div>
        <span
          >{{ student.dynamicData?.academicHistory1?.schoolName }}
          {{ student.dynamicData?.academicHistory1?.departmentName }}</span
        >
      </div>
      <div class="personal__actions" *ngIf="!readonly">
        <button class="btn btn--green" (click)="openMatching()">
          マッチング
        </button>
        <button class="btn btn--green" (click)="openMailSend()">
          レジュメ送信
        </button>
        <div
          class="refresh refresh--margin-left"
          [ngClass]="{ 'refresh--flash': isRefreshButtonFlashed }"
          (click)="refresh()"
        >
          <div class="refresh-icon">
            <svg class="refresh--svg">
              <use xlink:href="/assets/icons/icn_refresh.svg#refresh"></use>
            </svg>
          </div>
          求職者の全情報を再取得する
        </div>
      </div>
    </div>
  </div>
  <span
    class="messages-container"
    #msgContainer
    [ngClass]="{
      'messages-container--open': messageShown,
      'messages-container--empty':
        student.studentNotes && !student.studentNotes.length && !editMode
    }"
  >
    <span class="messages-container__info">伝言</span>
    <div
      *ngFor="
        let message of student.studentNotes?.length ? student.studentNotes : [];
        let i = index
      "
      class="message"
    >
      <span
        *ngIf="!messageEdit[i]"
        class="message__text"
        title="{{ message.note }}"
        [ngClass]="{
          'message__text--open': messageShown
        }"
      >
        {{ message.note || "" }}
      </span>
      <textarea
        *ngIf="messageEdit[i]"
        (keypress.esc)="onMessageEditDone(i, false)"
        (keydown.enter)="$event.stopPropagation()"
        class="message__text message__text--editor"
        [defaultValue]="message.note || ''"
        [(ngModel)]="tempMessages[i]"
      ></textarea>
      <div class="message__edit-actions" *ngIf="messageEdit[i]">
        <button
          class="message__edit-control"
          (click)="
            onMessageEditDone(i, false, tempMessages[i]);
            tempMessages[i] = undefined
          "
        >
          キャンセル
        </button>
        <button
          class="message__edit-control message__edit-control--save"
          (click)="
            onMessageEditDone(i, true, tempMessages[i]);
            tempMessages[i] = undefined
          "
        >
          更新
        </button>
      </div>
      <div
        class="message__control"
        *ngIf="
          !readonly &&
          !messageEdit[i] &&
          (message || student.studentNotes?.length)
        "
        (click)="
          tempMessages[i] = message.note;
          messageShown = true;
          messageEdit[i] = true
        "
      >
        <svg class="message__control--edit">
          <use xlink:href="/assets/icons/pencil_tilt.svg#pencil"></use>
        </svg>
      </div>
      <div
        class="message__control"
        *ngIf="
          !readonly &&
          !messageEdit[i] &&
          (message || student.studentNotes?.length)
        "
        (click)="onMessageDelete(i)"
      >
        <svg class="message__control--delete">
          <use xlink:href="/assets/icons/trash_can.svg#trash_can"></use>
        </svg>
      </div>
    </div>
    <span
      class="message__note"
      *ngIf="!readonly && !editMode"
      (click)="onMessageAdd()"
      >新規入力</span
    >
    <span
      class="message__arrow"
      *ngIf="!editMode && student.studentNotes?.length"
      [ngClass]="{
        'message__arrow--down': !messageShown,
        'message__arrow--up': messageShown
      }"
      (click)="messageShown = !messageShown"
    ></span>
  </span>
</ng-container>
