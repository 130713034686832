import { SalesAmount } from '@agent-ds/shared/interfaces/sale';
import { DashboardType, Student, CustomerList, ExpectedCompany, ResponseStatus } from '@agent-ds/shared/interfaces';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { MailSendConfig } from '@agent-ds/shared/components/mail-send/config';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { StudentDetailConfig } from '@agent-ds/shared/components/mail-send/configs/student-detail-config';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';

import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { ExportModel } from '@agent-ds/shared/interfaces/export';
import { ListPage } from '@agent-ds/shared/models/list-page';
import { AuthService, DialogService, ExportApiService, MasterApiService, StudentApiService, UserApiService, SalesApiService, CustomerApiService } from '@agent-ds/shared/services';
import { cleanObject, getValueFromObject } from '@agent-ds/shared/util/util';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';

import { StudentTabs } from '@agent-ds/student/pages/student-detail-page/tabs/student-tabs';
import { Location } from '@angular/common';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { PROGRESS_STATUSES } from '@agent-ds/shared/constants';

import { PageScrollTableComponent } from '../../page-scroll-table/page-scroll-table.component';
import { BatchResult } from '../../page-scroll-table/table-interface';
import { GLOBAL_CHEADER_CONFIG } from './global-cheader-config';

@Component({
  selector: 'ag-global-cheader',
  templateUrl: './global-cheader.component.html',
  styleUrls: ['./global-cheader.component.scss'],
})
export class GlobalCustomerHeaderComponent implements OnInit {
  // @ViewChild('customerActiveList', { static: false }) tableActive: CustomerActiveListComponent;
  // @ViewChild('customerNotActiveList', { static: false }) tableNotActive: CustomerNotActiveListComponent;
  @ViewChild(PageScrollTableComponent, { static: false }) table: PageScrollTableComponent;
  @ViewChild('check5', { static: false }) check5Template: TemplateRef<any>;
  @ViewChild('customer', { static: false }) customerTemplate: TemplateRef<any>;
  @ViewChild('school', { static: false }) schoolTemplate: TemplateRef<any>;
  @ViewChild('graduateYear', { static: false }) graduateYearTemplate: TemplateRef<any>;
  @ViewChild('universityLevel', { static: false }) universityLevelTemplate: TemplateRef<any>;
  @ViewChild('sf', { static: false }) sfTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: false }) actionsTemplate: TemplateRef<any>;
  @ViewChild('textfield', { static: false }) textfieldTemplate: TemplateRef<any>;
  @ViewChild('weekPoint', { static: false }) weekPointTemplate: TemplateRef<any>;
  @ViewChild('is2ndMatch', { static: false }) is2ndMatchTemplate: TemplateRef<any>;
  @ViewChild('check5Introduce', { static: false }) check5IntroduceTemplate: TemplateRef<any>;
  @ViewChild('check5Apply', { static: false }) check5ApplyTemplate: TemplateRef<any>;
  @ViewChild('mypageLoggedInAt', { static: false }) mypageLoggedInAtTemplate: TemplateRef<any>;
  @ViewChild('documentSubmissionStatus', { static: false }) documentSubmissionStatusTemplate: TemplateRef<any>;
  
  @Output() readonly itemChecked = new EventEmitter<{ item?: any; checked: boolean }>();

  enterprises = []
  studentTabs = {
    OVERVIEW: 0,
    DETAIL: 1,
    ACADEMIC: 2,
    PREFERENCES: 3,
    PROGRESS: 4,
    CONTACTS: 5,
    MESSAGE: 6,
    MEMO: 7,
    FILES: 8,
    SEMINARS: 9,
    MATCHES: 10,
  }

  detailConfig = new StudentDetailConfig();
  sendConfig = new StudentSendConfig();

  tabs = StudentTabs;
  initialized = false;
  activeSortStatus = 'asc';
  progressSortStatus = 'asc';
  sfSortStatus = 'asc';
  graduateYearSortStatus = 'asc';
  interviewSortStatus = 'asc';
  pickUpMemoRegisterSortStatus = 'asc';
  is2ndMatchSortStatus = 'asc';
  check5IntroduceSortStatus = 'asc';
  check5ApplySortStatus = 'asc';
  mypageLoggedInAtSortStatus = 'asc';
  documentSubmissionStatusSortStatus = 'asc';

  get checkedItems(): Student[] {
    return this.table ? this.table.checkedItems : [];
  }

  get allChecked(): boolean {
    return this.table.allChecked;
  }

  get totalSize(): number {
    return this.table.totalSize;
  }

  init = false;
  isCustomer = false;
  loadingCustomer = false;

  // customers: Customer[] = [];
  customers: Student[] = [];
  customer_total: number = 0;
  expectedCompanies: {} = {};
  expectedCompaniesWithoutExpected: {} = {};
  expectedCompany: {} = {};
  expectedData: {} = {};
  pickUpMemo: {} = {};

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
    protected exportService: ExportApiService,
    private salesService: SalesApiService,
    private dialog: DialogService,
    private datePipe: SafeDatePipe,
    private authService: AuthService,
    private studentApiService: StudentApiService,
    private customerApiService: CustomerApiService,
    public readonly masterService: MasterApiService,
    public readonly userService: UserApiService,
    public readonly userTeamInjector: UserTeamInjectorProvider,
    public readonly fb: FormBuilder,
  ) {}

  private condition: {
    contractDate?: { from?: string; to?: string };
    interviewDate?: { from?: string; to?: string };
  } = {};
  model: SalesAmount = { ca: { users: [], total: null }, rapa: { users: [], total: null } };


  

  ngOnInit() {
    // this.salesService.getReport(this.condition).subscribe((salesAmount: SalesAmount) => {
    //   this.model = salesAmount;
    // });
  }

  handlerChangeExpectedCompany(studentId: number, newExpectedCompany: number): void {
    // console.log('handlerChangeExpectedCompany studentId', studentId);
    // console.log('handlerChangeExpectedCompany newExpectedCompany', newExpectedCompany);

    const currentProgressId = (() => {
      const currentExpectedCompany = this.expectedCompanies[studentId].find((ec) => ec.p_expected_company_flag === '1');
      if (!currentExpectedCompany) {
        return 0;
      }

      return currentExpectedCompany.p_id;
    })(); 
    // console.log('handlerChangeExpectedCompany currentProgressId', currentProgressId);

    // newExpectedCompany === progressIdと同じ 
    this.customerApiService
      .updateExpectedCompanyFlag(studentId, currentProgressId, newExpectedCompany)
      .subscribe((responseStatus: ResponseStatus) => {
        if (responseStatus.status) {
          this.expectedCompaniesWithoutExpected[studentId] = this.expectedCompanies[studentId].filter((ec) => ec.p_id !== Number(newExpectedCompany)).slice(0, 3);
          const expectedData = this.expectedCompanies[studentId].find((ec) => {
            if (ec.p_id === Number(newExpectedCompany)) {
              return true;
            }
            return false;
          });
          this.expectedData[studentId] = expectedData;

          // console.log('handlerChangeExpectedCompany expectedCompaniesWithoutExpected', this.expectedCompaniesWithoutExpected);
        };
      });
  }

  getSearchQueryParams(userId, progressType, agentType, canceled) {
    const params: any = {
      hasProgress: 1,
      progressType: progressType,
      status: 2,
    };
    if (agentType === 1) {
      params.paraUserId = userId;
    }
    if (agentType === 3) {
      params.caUserId = userId;
    }
    if (this.condition.contractDate) {
      params.contractOrCancelDateFrom = this.condition.contractDate.from;
      params.contractOrCancelDateTo = this.condition.contractDate.to;
    }
    if (this.condition.interviewDate) {
      params.interviewDateFrom = this.condition.interviewDate.from;
      params.interviewDateTo = this.condition.interviewDate.to;
    }
    if (canceled) {
      params.cancelStatus = 3;
    }
    return params;
  }

  toggleCustomer(): void {
    this.isCustomer = !this.isCustomer;
    // console.log('this.isCustomer', this.isCustomer);
    // if (!this.init) {
    if (this.isCustomer) {
      this.loadingCustomer = true;

      const params = cleanObject({
        from: 0,
        size: 200,
        sort: 'registrationStatus',
        order: 'desc',
      });
  
      this.customerApiService
        .getList(params)
        .subscribe((customerList: CustomerList) => {
          this.customers = customerList.students;
          this.customers.sort((a, b) => {
            if (!b.dynamicData.rank || a.dynamicData.rank < b.dynamicData.rank) {
              return -1;
            }
            if (a.dynamicData.rank > b.dynamicData.rank) {
              return 1;
            }
            return 0;
          });
          this.customer_total = customerList.total;
          this.expectedCompanies = customerList.expectedCompanies;

          const studentIds = Object.keys(this.expectedCompanies);
          studentIds.forEach((studentId) => {
            // console.log('studentId', studentId);

            const foundExpectedCompany = this.expectedCompanies[studentId].find((expectedCompany) => expectedCompany.p_expected_company_flag === '1')
            // console.log('foundExpectedCompany', foundExpectedCompany);
            if (foundExpectedCompany) {
              this.expectedCompany[studentId] = foundExpectedCompany.p_id;
              this.expectedData[studentId] = foundExpectedCompany;
              this.expectedCompaniesWithoutExpected[studentId] = this.expectedCompanies[studentId].filter((ec) => ec.p_id !== foundExpectedCompany.p_id).slice(0, 3);
            } else {
              this.expectedCompany[studentId] = [];
              this.expectedData[studentId] = {};
              this.expectedCompaniesWithoutExpected[studentId] = [];
            }

            const customer = this.customers.find((cus) => cus.id === Number(studentId))
            // console.log('customer', customer);
            if (customer && customer.studentSituationMemo && customer.studentSituationMemo.length >= 1) {
              customer.studentSituationMemo.sort((a, b) => {
                if (a.registerDate > b.registerDate) {
                  return -1;
                }
                if (a.registerDate < b.registerDate) {
                  return 1;
                }
                return 0;
              });
              this.pickUpMemo[studentId] = customer.studentSituationMemo[0];
            } else {
              this.pickUpMemo[studentId] = null;
            }
          });

          this.loadingCustomer = false;

          // console.log('this.customers', this.customers);
          // console.log('this.customer_total', this.customer_total);
          // console.log('this.expectedCompanies', this.expectedCompanies);
          // console.log('this.expectedCompany', this.expectedCompany);
          // console.log('this.pickUpMemo', this.pickUpMemo);
        });

      setTimeout(() => {
        this.loadingCustomer = false;
      }, 3000);
    }
  }

  openChat(lineId): void {
    if (!lineId) {
      return;
    }
    const url = `https://aura-mico.jp/students/${lineId}`;
    window.open(url, "_blank");
  }

  handlerGraduateYearView(year): String {
    // console.log('year', year);
    if (!isNaN(year)) {
      const newYear = String(year);
      return newYear.slice(2);
    }
    return year.slice(2);
  }

  handlerDateMonthDay(dateData): String {
    // console.log('dateData', dateData);
    const newMonthDay =  dateData.slice(5, 10);

    return newMonthDay.replace("-", '/');
  }

  onItemSelect(selectedItem: any): void {
    StudentDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
    StudentDetailPageComponent.instance.open();
  }

  onItemSelectAndMemo(selectedItem: any): void {
    StudentDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
    StudentDetailPageComponent.instance.selectedTabIndex = this.studentTabs.MEMO;
    StudentDetailPageComponent.instance.open();
  }

  openMailSend(config: MailSendConfig = this.detailConfig, student): void {
    StudentDetailPageComponent.instance.referenceId = student ? student.id : null;
    StudentDetailPageComponent.instance.open();

    // 一旦不要とのこと
    // if (this.authService.authInfo.zone !== 'zone3') {
    //   PopupControlComponent.instance.open({
    //     title: null,
    //     content: 'ZONE3専用です。',
    //     confirmText: 'OK',
    //     confirmCallback: () => false,
    //   });
    //   return;
    // }
    MailSendFlowComponent.instance.config = config;
    config.setParams({
      student: {
        id: student.id,
        frontId: student.frontId,
        firstName: student.dynamicData.firstName,
        lastName: student.dynamicData.lastName,
        age: null,
        prefecture: null,
        schoolName: null,
        schoolDepartmentName: null,
        studentUser: student.studentUsers,
        emailMain: getValueFromObject(student, 'dynamicData.emailMain.email'),
        emailMainAvailable: getValueFromObject(student, 'dynamicData.emailMain.emailAvailable'),
        emailSub: getValueFromObject(student, 'dynamicData.emailSub.email'),
        emailSubAvailable: getValueFromObject(student, 'dynamicData.emailSub.emailAvailable'),
      },
    });
    MailSendFlowComponent.instance.start();
  }

  handlerSituationMemoWishView(picMemo): String {
    if (!picMemo) {
      return '';
    }

    const memo = picMemo.situationMemo;

    const wishMemo = memo.indexOf('志望状況');
    const nextMemo = memo.indexOf('ネクストアクション');

    if (wishMemo !== -1 && nextMemo !== -1) {
      const cut = memo.slice(wishMemo, nextMemo - 3);
      return cut.replace("志望状況", '').trim();
    }

    return '';
  }

  handlerSituationMemoNextView(picMemo): String {
    if (!picMemo) {
      return '';
    }

    const memo = picMemo.situationMemo;

    const nextMemo = memo.indexOf('ネクストアクション');
    const etcMemo = memo.indexOf('その他メモ');

    if (nextMemo !== -1 && etcMemo !== -1) {
      const cut = memo.slice(nextMemo, etcMemo - 3);
      return cut.replace("ネクストアクション", '').trim();
    }

    return '';
  }

  handlerSituationMemoEtcView(picMemo): String {
    if (!picMemo) {
      return '';
    }

    const memo = picMemo.situationMemo;

    const etcMemo = memo.indexOf('その他メモ');

    if (etcMemo !== -1) {
      const cut = memo.slice(etcMemo);
      return cut.replace("その他メモ", '').trim();
    }

    return '';
  }

  handlerCheckExpectedCompanies(studentId) {
    if (!(studentId in this.expectedCompanies)) {
      return null;
    }

    if (this.expectedCompanies[studentId].length === 0) {
      return null;
    }

    return true;
  }

  handlerExpectedCompanyTypeView(type): String {
    if (type === 0) {
      return 'AG';
    }

    if (type === 1) {
      return 'PLUS';
    }

    return '';
  }

  handlerProgressStatusView(expectedCompany: ExpectedCompany): String {
    // console.log('expectedCompany', expectedCompany);
    let word = '';

    if (!expectedCompany.p_status || !(expectedCompany.p_status in PROGRESS_STATUSES)) {
      return word;
    }

    if (expectedCompany.p_n) {
      word = `${expectedCompany.p_n}次`;
    }

    word = word + PROGRESS_STATUSES[expectedCompany.p_status].label;

    return word;
  }

  handlerCompanyNameReplace(name: String): String {
    if (name) {
      return name.replace('株式会社', '');
    }

    return '';
  }

  handlerCheck5PickupMemoStatus(studentId: number): boolean {
    const dPick = this.pickUpMemo[studentId];
    if (!dPick || !dPick.registerDate) {
      return false;
    }

    const now = new Date();

    // 7日以内判定
    return this.isBetweenDate(now, new Date(dPick.registerDate), 7);
  }

  isBetweenDate( now: Date, exDay: Date, num: number ){

    // 差分を絶対値で取得
    const days = Math.abs(exDay.getTime() - now.getTime());
  
    // 日単位に変換
    const betweenDates = days / (24 * 60 * 60 * 1000);

    // console.log('betweenDates', betweenDates);
  
    return betweenDates < num;
  
  }

  handlerCheck5SecondMatch(is2ndMatch: []): boolean {
    // console.log('is2ndMatch', is2ndMatch);
    if (is2ndMatch && is2ndMatch.length >= 1) {
      const found = is2ndMatch.find((second) => second === '有効');
      if (found) {
        return true;
      }
    }

    return false;
  }

  handlerCheck5DocumentSubmissionStatus(document: String): boolean {
    if (document && (document === '有' || document === 'OM有' || document === 'キャリアノート')) {
      return true;
    }

    return false;
  }

  handlerActiveSort() {
    if (this.activeSortStatus === 'asc') {
      this.activeSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.activeCount || a.activeCount > b.activeCount) {
          return -1;
        }
        if (a.activeCount < b.activeCount) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.activeSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.activeCount || a.activeCount < b.activeCount) {
        return -1;
      }
      if (a.activeCount > b.activeCount) {
        return 1;
      }
      return 0;
    });
  }

  handlerProgressSort() {
    if (this.progressSortStatus === 'asc') {
      this.progressSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.progressCount || a.progressCount > b.progressCount) {
          return -1;
        }
        if (a.progressCount < b.progressCount) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.progressSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.progressCount || a.progressCount < b.progressCount) {
        return -1;
      }
      if (a.progressCount > b.progressCount) {
        return 1;
      }
      return 0;
    });
  }

  handlerGraduateYearSort() {
    if (this.graduateYearSortStatus === 'asc') {
      this.graduateYearSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.academicHistory1.graduateYear || a.dynamicData.academicHistory1.graduateYear > b.dynamicData.academicHistory1.graduateYear) {
          return -1;
        }
        if (a.dynamicData.academicHistory1.graduateYear < b.dynamicData.academicHistory1.graduateYear) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.graduateYearSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.academicHistory1.graduateYear || a.dynamicData.academicHistory1.graduateYear < b.dynamicData.academicHistory1.graduateYear) {
        return -1;
      }
      if (a.dynamicData.academicHistory1.graduateYear > b.dynamicData.academicHistory1.graduateYear) {
        return 1;
      }
      return 0;
    });
  }

  handlerInterviewSort() {
    if (this.interviewSortStatus === 'asc') {
      this.interviewSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.interviewDate || a.dynamicData.interviewDate > b.dynamicData.interviewDate) {
          return -1;
        }
        if (a.dynamicData.interviewDate < b.dynamicData.interviewDate) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.interviewSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.interviewDate || a.dynamicData.interviewDate < b.dynamicData.interviewDate) {
        return -1;
      }
      if (a.dynamicData.interviewDate > b.dynamicData.interviewDate) {
        return 1;
      }
      return 0;
    });
  }

  handlerSfSort() {
    if (this.sfSortStatus === 'asc') {
      this.sfSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.rank || a.dynamicData.rank > b.dynamicData.rank) {
          return -1;
        }
        if (a.dynamicData.rank < b.dynamicData.rank) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.sfSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.rank || a.dynamicData.rank < b.dynamicData.rank) {
        return -1;
      }
      if (a.dynamicData.rank > b.dynamicData.rank) {
        return 1;
      }
      return 0;
    });
  }

  handlerPickUpMemoRegisterSort() {
    if (this.pickUpMemoRegisterSortStatus === 'asc') {
      this.pickUpMemoRegisterSortStatus = 'desc';

      this.customers.sort((a, b) => {
        const aPick = this.pickUpMemo[a.id];
        const bPick = this.pickUpMemo[b.id];
        // console.log('aPick', aPick);
        // console.log('bPick', bPick);

        if (!aPick && !bPick) {
          return 0;
        }
        if (aPick === bPick) {
          return 0;
        }
        if (!bPick || !bPick.registerDate || (aPick && aPick.registerDate > bPick.registerDate)) {
          return -1;
        }
        return 1;
      });
      return;
    }

    this.pickUpMemoRegisterSortStatus = 'asc';
    this.customers.sort((a, b) => {
      const aPick = this.pickUpMemo[a.id];
      const bPick = this.pickUpMemo[b.id];

      if (!aPick && !bPick) {
        return 0;
      }
      if (aPick === bPick) {
        return 0;
      }
      if (!aPick || !aPick.registerDate || (bPick && aPick.registerDate < bPick.registerDate)) {
        return -1;
      }
      return 1;
    });
  }

  handlerIs2ndMatchSort() {
    if (this.is2ndMatchSortStatus === 'asc') {
      this.is2ndMatchSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.is2ndMatch || a.dynamicData.is2ndMatch > b.dynamicData.is2ndMatch) {
          return -1;
        }
        if (a.dynamicData.is2ndMatch < b.dynamicData.is2ndMatch) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.is2ndMatchSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.is2ndMatch || a.dynamicData.is2ndMatch < b.dynamicData.is2ndMatch) {
        return -1;
      }
      if (a.dynamicData.is2ndMatch > b.dynamicData.is2ndMatch) {
        return 1;
      }
      return 0;
    });
  }

  handlerCheck5IntroduceSort() {
    if (this.check5IntroduceSortStatus === 'asc') {
      this.check5IntroduceSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.check5Introduce || a.dynamicData.check5Introduce > b.dynamicData.check5Introduce) {
          return -1;
        }
        if (a.dynamicData.check5Introduce < b.dynamicData.check5Introduce) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.check5IntroduceSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.check5Introduce || a.dynamicData.check5Introduce < b.dynamicData.check5Introduce) {
        return -1;
      }
      if (a.dynamicData.check5Introduce > b.dynamicData.check5Introduce) {
        return 1;
      }
      return 0;
    });
  }

  handlerCheck5ApplySort() {
    if (this.check5ApplySortStatus === 'asc') {
      this.check5ApplySortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.check5Apply || a.dynamicData.check5Apply > b.dynamicData.check5Apply) {
          return -1;
        }
        if (a.dynamicData.check5Apply < b.dynamicData.check5Apply) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.check5ApplySortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.check5Apply || a.dynamicData.check5Apply < b.dynamicData.check5Apply) {
        return -1;
      }
      if (a.dynamicData.check5Apply > b.dynamicData.check5Apply) {
        return 1;
      }
      return 0;
    });
  }

  handlerMypageLoggedInAtSort() {
    if (this.mypageLoggedInAtSortStatus === 'asc') {
      this.mypageLoggedInAtSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.mypageLoggedInAt || a.mypageLoggedInAt > b.mypageLoggedInAt) {
          return -1;
        }
        if (a.mypageLoggedInAt < b.mypageLoggedInAt) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.mypageLoggedInAtSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.mypageLoggedInAt || a.mypageLoggedInAt < b.mypageLoggedInAt) {
        return -1;
      }
      if (a.mypageLoggedInAt > b.mypageLoggedInAt) {
        return 1;
      }
      return 0;
    });
  }

  handlerDocumentSubmissionStatusSort() {
    if (this.documentSubmissionStatusSortStatus === 'asc') {
      this.documentSubmissionStatusSortStatus = 'desc';

      this.customers.sort((a, b) => {
        if (!b.dynamicData.documentSubmissionStatus || a.dynamicData.documentSubmissionStatus > b.dynamicData.documentSubmissionStatus) {
          return -1;
        }
        if (a.dynamicData.documentSubmissionStatus < b.dynamicData.documentSubmissionStatus) {
          return 1;
        }
        return 0;
      });
      return;
    }

    this.documentSubmissionStatusSortStatus = 'asc';
    this.customers.sort((a, b) => {
      if (!a.dynamicData.documentSubmissionStatus || a.dynamicData.documentSubmissionStatus < b.dynamicData.documentSubmissionStatus) {
        return -1;
      }
      if (a.dynamicData.documentSubmissionStatus > b.dynamicData.documentSubmissionStatus) {
        return 1;
      }
      return 0;
    });
  }
}
