import { Student, CustomerList, ExpectedCompany, ResponseStatus } from '@agent-ds/shared/interfaces';
import { MailSendFlowComponent } from '@agent-ds/shared/components/mail-send-flow/mail-send-flow.component';
import { StudentDetailConfig } from '@agent-ds/shared/components/mail-send/configs/student-detail-config';
import { StudentSendConfig } from '@agent-ds/shared/components/mail-send/configs/student-send-config';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, DialogService, ExportApiService, MasterApiService, StudentApiService, UserApiService, SalesApiService, CustomerApiService } from '@agent-ds/shared/services';
import { cleanObject, getValueFromObject } from '@agent-ds/shared/util/util';
import { StudentDetailPageComponent } from '@agent-ds/student/pages/student-detail-page/student-detail-page.component';
import { StudentTabs } from '@agent-ds/student/pages/student-detail-page/tabs/student-tabs';
import { Location } from '@angular/common';
import { UserTeamInjectorProvider } from '@agent-ds/shared/services/api/providers/user-team-injector.provider';
import { EventEmitter, OnInit, TemplateRef, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PROGRESS_STATUSES } from '@agent-ds/shared/constants';
import { PageScrollTableComponent } from '../../page-scroll-table/page-scroll-table.component';
var GlobalCustomerHeaderComponent = /** @class */ (function () {
    function GlobalCustomerHeaderComponent(router, activeRoute, location, exportService, salesService, dialog, datePipe, authService, studentApiService, customerApiService, masterService, userService, userTeamInjector, fb) {
        this.router = router;
        this.activeRoute = activeRoute;
        this.location = location;
        this.exportService = exportService;
        this.salesService = salesService;
        this.dialog = dialog;
        this.datePipe = datePipe;
        this.authService = authService;
        this.studentApiService = studentApiService;
        this.customerApiService = customerApiService;
        this.masterService = masterService;
        this.userService = userService;
        this.userTeamInjector = userTeamInjector;
        this.fb = fb;
        this.itemChecked = new EventEmitter();
        this.enterprises = [];
        this.studentTabs = {
            OVERVIEW: 0,
            DETAIL: 1,
            ACADEMIC: 2,
            PREFERENCES: 3,
            PROGRESS: 4,
            CONTACTS: 5,
            MESSAGE: 6,
            MEMO: 7,
            FILES: 8,
            SEMINARS: 9,
            MATCHES: 10,
        };
        this.detailConfig = new StudentDetailConfig();
        this.sendConfig = new StudentSendConfig();
        this.tabs = StudentTabs;
        this.initialized = false;
        this.activeSortStatus = 'asc';
        this.progressSortStatus = 'asc';
        this.sfSortStatus = 'asc';
        this.graduateYearSortStatus = 'asc';
        this.interviewSortStatus = 'asc';
        this.pickUpMemoRegisterSortStatus = 'asc';
        this.is2ndMatchSortStatus = 'asc';
        this.check5IntroduceSortStatus = 'asc';
        this.check5ApplySortStatus = 'asc';
        this.mypageLoggedInAtSortStatus = 'asc';
        this.documentSubmissionStatusSortStatus = 'asc';
        this.init = false;
        this.isCustomer = false;
        this.loadingCustomer = false;
        // customers: Customer[] = [];
        this.customers = [];
        this.customer_total = 0;
        this.expectedCompanies = {};
        this.expectedCompaniesWithoutExpected = {};
        this.expectedCompany = {};
        this.expectedData = {};
        this.pickUpMemo = {};
        this.condition = {};
        this.model = { ca: { users: [], total: null }, rapa: { users: [], total: null } };
    }
    Object.defineProperty(GlobalCustomerHeaderComponent.prototype, "checkedItems", {
        get: function () {
            return this.table ? this.table.checkedItems : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalCustomerHeaderComponent.prototype, "allChecked", {
        get: function () {
            return this.table.allChecked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalCustomerHeaderComponent.prototype, "totalSize", {
        get: function () {
            return this.table.totalSize;
        },
        enumerable: true,
        configurable: true
    });
    GlobalCustomerHeaderComponent.prototype.ngOnInit = function () {
        // this.salesService.getReport(this.condition).subscribe((salesAmount: SalesAmount) => {
        //   this.model = salesAmount;
        // });
    };
    GlobalCustomerHeaderComponent.prototype.handlerChangeExpectedCompany = function (studentId, newExpectedCompany) {
        // console.log('handlerChangeExpectedCompany studentId', studentId);
        // console.log('handlerChangeExpectedCompany newExpectedCompany', newExpectedCompany);
        var _this = this;
        var currentProgressId = (function () {
            var currentExpectedCompany = _this.expectedCompanies[studentId].find(function (ec) { return ec.p_expected_company_flag === '1'; });
            if (!currentExpectedCompany) {
                return 0;
            }
            return currentExpectedCompany.p_id;
        })();
        // console.log('handlerChangeExpectedCompany currentProgressId', currentProgressId);
        // newExpectedCompany === progressIdと同じ 
        this.customerApiService
            .updateExpectedCompanyFlag(studentId, currentProgressId, newExpectedCompany)
            .subscribe(function (responseStatus) {
            if (responseStatus.status) {
                _this.expectedCompaniesWithoutExpected[studentId] = _this.expectedCompanies[studentId].filter(function (ec) { return ec.p_id !== Number(newExpectedCompany); }).slice(0, 3);
                var expectedData = _this.expectedCompanies[studentId].find(function (ec) {
                    if (ec.p_id === Number(newExpectedCompany)) {
                        return true;
                    }
                    return false;
                });
                _this.expectedData[studentId] = expectedData;
                // console.log('handlerChangeExpectedCompany expectedCompaniesWithoutExpected', this.expectedCompaniesWithoutExpected);
            }
            ;
        });
    };
    GlobalCustomerHeaderComponent.prototype.getSearchQueryParams = function (userId, progressType, agentType, canceled) {
        var params = {
            hasProgress: 1,
            progressType: progressType,
            status: 2,
        };
        if (agentType === 1) {
            params.paraUserId = userId;
        }
        if (agentType === 3) {
            params.caUserId = userId;
        }
        if (this.condition.contractDate) {
            params.contractOrCancelDateFrom = this.condition.contractDate.from;
            params.contractOrCancelDateTo = this.condition.contractDate.to;
        }
        if (this.condition.interviewDate) {
            params.interviewDateFrom = this.condition.interviewDate.from;
            params.interviewDateTo = this.condition.interviewDate.to;
        }
        if (canceled) {
            params.cancelStatus = 3;
        }
        return params;
    };
    GlobalCustomerHeaderComponent.prototype.toggleCustomer = function () {
        var _this = this;
        this.isCustomer = !this.isCustomer;
        // console.log('this.isCustomer', this.isCustomer);
        // if (!this.init) {
        if (this.isCustomer) {
            this.loadingCustomer = true;
            var params = cleanObject({
                from: 0,
                size: 200,
                sort: 'registrationStatus',
                order: 'desc',
            });
            this.customerApiService
                .getList(params)
                .subscribe(function (customerList) {
                _this.customers = customerList.students;
                _this.customers.sort(function (a, b) {
                    if (!b.dynamicData.rank || a.dynamicData.rank < b.dynamicData.rank) {
                        return -1;
                    }
                    if (a.dynamicData.rank > b.dynamicData.rank) {
                        return 1;
                    }
                    return 0;
                });
                _this.customer_total = customerList.total;
                _this.expectedCompanies = customerList.expectedCompanies;
                var studentIds = Object.keys(_this.expectedCompanies);
                studentIds.forEach(function (studentId) {
                    // console.log('studentId', studentId);
                    var foundExpectedCompany = _this.expectedCompanies[studentId].find(function (expectedCompany) { return expectedCompany.p_expected_company_flag === '1'; });
                    // console.log('foundExpectedCompany', foundExpectedCompany);
                    if (foundExpectedCompany) {
                        _this.expectedCompany[studentId] = foundExpectedCompany.p_id;
                        _this.expectedData[studentId] = foundExpectedCompany;
                        _this.expectedCompaniesWithoutExpected[studentId] = _this.expectedCompanies[studentId].filter(function (ec) { return ec.p_id !== foundExpectedCompany.p_id; }).slice(0, 3);
                    }
                    else {
                        _this.expectedCompany[studentId] = [];
                        _this.expectedData[studentId] = {};
                        _this.expectedCompaniesWithoutExpected[studentId] = [];
                    }
                    var customer = _this.customers.find(function (cus) { return cus.id === Number(studentId); });
                    // console.log('customer', customer);
                    if (customer && customer.studentSituationMemo && customer.studentSituationMemo.length >= 1) {
                        customer.studentSituationMemo.sort(function (a, b) {
                            if (a.registerDate > b.registerDate) {
                                return -1;
                            }
                            if (a.registerDate < b.registerDate) {
                                return 1;
                            }
                            return 0;
                        });
                        _this.pickUpMemo[studentId] = customer.studentSituationMemo[0];
                    }
                    else {
                        _this.pickUpMemo[studentId] = null;
                    }
                });
                _this.loadingCustomer = false;
                // console.log('this.customers', this.customers);
                // console.log('this.customer_total', this.customer_total);
                // console.log('this.expectedCompanies', this.expectedCompanies);
                // console.log('this.expectedCompany', this.expectedCompany);
                // console.log('this.pickUpMemo', this.pickUpMemo);
            });
            setTimeout(function () {
                _this.loadingCustomer = false;
            }, 3000);
        }
    };
    GlobalCustomerHeaderComponent.prototype.openChat = function (lineId) {
        if (!lineId) {
            return;
        }
        var url = "https://aura-mico.jp/students/" + lineId;
        window.open(url, "_blank");
    };
    GlobalCustomerHeaderComponent.prototype.handlerGraduateYearView = function (year) {
        // console.log('year', year);
        if (!isNaN(year)) {
            var newYear = String(year);
            return newYear.slice(2);
        }
        return year.slice(2);
    };
    GlobalCustomerHeaderComponent.prototype.handlerDateMonthDay = function (dateData) {
        // console.log('dateData', dateData);
        var newMonthDay = dateData.slice(5, 10);
        return newMonthDay.replace("-", '/');
    };
    GlobalCustomerHeaderComponent.prototype.onItemSelect = function (selectedItem) {
        StudentDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
        StudentDetailPageComponent.instance.open();
    };
    GlobalCustomerHeaderComponent.prototype.onItemSelectAndMemo = function (selectedItem) {
        StudentDetailPageComponent.instance.referenceId = selectedItem ? selectedItem.id : null;
        StudentDetailPageComponent.instance.selectedTabIndex = this.studentTabs.MEMO;
        StudentDetailPageComponent.instance.open();
    };
    GlobalCustomerHeaderComponent.prototype.openMailSend = function (config, student) {
        if (config === void 0) { config = this.detailConfig; }
        StudentDetailPageComponent.instance.referenceId = student ? student.id : null;
        StudentDetailPageComponent.instance.open();
        // 一旦不要とのこと
        // if (this.authService.authInfo.zone !== 'zone3') {
        //   PopupControlComponent.instance.open({
        //     title: null,
        //     content: 'ZONE3専用です。',
        //     confirmText: 'OK',
        //     confirmCallback: () => false,
        //   });
        //   return;
        // }
        MailSendFlowComponent.instance.config = config;
        config.setParams({
            student: {
                id: student.id,
                frontId: student.frontId,
                firstName: student.dynamicData.firstName,
                lastName: student.dynamicData.lastName,
                age: null,
                prefecture: null,
                schoolName: null,
                schoolDepartmentName: null,
                studentUser: student.studentUsers,
                emailMain: getValueFromObject(student, 'dynamicData.emailMain.email'),
                emailMainAvailable: getValueFromObject(student, 'dynamicData.emailMain.emailAvailable'),
                emailSub: getValueFromObject(student, 'dynamicData.emailSub.email'),
                emailSubAvailable: getValueFromObject(student, 'dynamicData.emailSub.emailAvailable'),
            },
        });
        MailSendFlowComponent.instance.start();
    };
    GlobalCustomerHeaderComponent.prototype.handlerSituationMemoWishView = function (picMemo) {
        if (!picMemo) {
            return '';
        }
        var memo = picMemo.situationMemo;
        var wishMemo = memo.indexOf('志望状況');
        var nextMemo = memo.indexOf('ネクストアクション');
        if (wishMemo !== -1 && nextMemo !== -1) {
            var cut = memo.slice(wishMemo, nextMemo - 3);
            return cut.replace("志望状況", '').trim();
        }
        return '';
    };
    GlobalCustomerHeaderComponent.prototype.handlerSituationMemoNextView = function (picMemo) {
        if (!picMemo) {
            return '';
        }
        var memo = picMemo.situationMemo;
        var nextMemo = memo.indexOf('ネクストアクション');
        var etcMemo = memo.indexOf('その他メモ');
        if (nextMemo !== -1 && etcMemo !== -1) {
            var cut = memo.slice(nextMemo, etcMemo - 3);
            return cut.replace("ネクストアクション", '').trim();
        }
        return '';
    };
    GlobalCustomerHeaderComponent.prototype.handlerSituationMemoEtcView = function (picMemo) {
        if (!picMemo) {
            return '';
        }
        var memo = picMemo.situationMemo;
        var etcMemo = memo.indexOf('その他メモ');
        if (etcMemo !== -1) {
            var cut = memo.slice(etcMemo);
            return cut.replace("その他メモ", '').trim();
        }
        return '';
    };
    GlobalCustomerHeaderComponent.prototype.handlerCheckExpectedCompanies = function (studentId) {
        if (!(studentId in this.expectedCompanies)) {
            return null;
        }
        if (this.expectedCompanies[studentId].length === 0) {
            return null;
        }
        return true;
    };
    GlobalCustomerHeaderComponent.prototype.handlerExpectedCompanyTypeView = function (type) {
        if (type === 0) {
            return 'AG';
        }
        if (type === 1) {
            return 'PLUS';
        }
        return '';
    };
    GlobalCustomerHeaderComponent.prototype.handlerProgressStatusView = function (expectedCompany) {
        // console.log('expectedCompany', expectedCompany);
        var word = '';
        if (!expectedCompany.p_status || !(expectedCompany.p_status in PROGRESS_STATUSES)) {
            return word;
        }
        if (expectedCompany.p_n) {
            word = expectedCompany.p_n + "\u6B21";
        }
        word = word + PROGRESS_STATUSES[expectedCompany.p_status].label;
        return word;
    };
    GlobalCustomerHeaderComponent.prototype.handlerCompanyNameReplace = function (name) {
        if (name) {
            return name.replace('株式会社', '');
        }
        return '';
    };
    GlobalCustomerHeaderComponent.prototype.handlerCheck5PickupMemoStatus = function (studentId) {
        var dPick = this.pickUpMemo[studentId];
        if (!dPick || !dPick.registerDate) {
            return false;
        }
        var now = new Date();
        // 7日以内判定
        return this.isBetweenDate(now, new Date(dPick.registerDate), 7);
    };
    GlobalCustomerHeaderComponent.prototype.isBetweenDate = function (now, exDay, num) {
        // 差分を絶対値で取得
        var days = Math.abs(exDay.getTime() - now.getTime());
        // 日単位に変換
        var betweenDates = days / (24 * 60 * 60 * 1000);
        // console.log('betweenDates', betweenDates);
        return betweenDates < num;
    };
    GlobalCustomerHeaderComponent.prototype.handlerCheck5SecondMatch = function (is2ndMatch) {
        // console.log('is2ndMatch', is2ndMatch);
        if (is2ndMatch && is2ndMatch.length >= 1) {
            var found = is2ndMatch.find(function (second) { return second === '有効'; });
            if (found) {
                return true;
            }
        }
        return false;
    };
    GlobalCustomerHeaderComponent.prototype.handlerCheck5DocumentSubmissionStatus = function (document) {
        if (document && (document === '有' || document === 'OM有' || document === 'キャリアノート')) {
            return true;
        }
        return false;
    };
    GlobalCustomerHeaderComponent.prototype.handlerActiveSort = function () {
        if (this.activeSortStatus === 'asc') {
            this.activeSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.activeCount || a.activeCount > b.activeCount) {
                    return -1;
                }
                if (a.activeCount < b.activeCount) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.activeSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.activeCount || a.activeCount < b.activeCount) {
                return -1;
            }
            if (a.activeCount > b.activeCount) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerProgressSort = function () {
        if (this.progressSortStatus === 'asc') {
            this.progressSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.progressCount || a.progressCount > b.progressCount) {
                    return -1;
                }
                if (a.progressCount < b.progressCount) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.progressSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.progressCount || a.progressCount < b.progressCount) {
                return -1;
            }
            if (a.progressCount > b.progressCount) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerGraduateYearSort = function () {
        if (this.graduateYearSortStatus === 'asc') {
            this.graduateYearSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.academicHistory1.graduateYear || a.dynamicData.academicHistory1.graduateYear > b.dynamicData.academicHistory1.graduateYear) {
                    return -1;
                }
                if (a.dynamicData.academicHistory1.graduateYear < b.dynamicData.academicHistory1.graduateYear) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.graduateYearSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.academicHistory1.graduateYear || a.dynamicData.academicHistory1.graduateYear < b.dynamicData.academicHistory1.graduateYear) {
                return -1;
            }
            if (a.dynamicData.academicHistory1.graduateYear > b.dynamicData.academicHistory1.graduateYear) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerInterviewSort = function () {
        if (this.interviewSortStatus === 'asc') {
            this.interviewSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.interviewDate || a.dynamicData.interviewDate > b.dynamicData.interviewDate) {
                    return -1;
                }
                if (a.dynamicData.interviewDate < b.dynamicData.interviewDate) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.interviewSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.interviewDate || a.dynamicData.interviewDate < b.dynamicData.interviewDate) {
                return -1;
            }
            if (a.dynamicData.interviewDate > b.dynamicData.interviewDate) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerSfSort = function () {
        if (this.sfSortStatus === 'asc') {
            this.sfSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.rank || a.dynamicData.rank > b.dynamicData.rank) {
                    return -1;
                }
                if (a.dynamicData.rank < b.dynamicData.rank) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.sfSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.rank || a.dynamicData.rank < b.dynamicData.rank) {
                return -1;
            }
            if (a.dynamicData.rank > b.dynamicData.rank) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerPickUpMemoRegisterSort = function () {
        var _this = this;
        if (this.pickUpMemoRegisterSortStatus === 'asc') {
            this.pickUpMemoRegisterSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                var aPick = _this.pickUpMemo[a.id];
                var bPick = _this.pickUpMemo[b.id];
                // console.log('aPick', aPick);
                // console.log('bPick', bPick);
                if (!aPick && !bPick) {
                    return 0;
                }
                if (aPick === bPick) {
                    return 0;
                }
                if (!bPick || !bPick.registerDate || (aPick && aPick.registerDate > bPick.registerDate)) {
                    return -1;
                }
                return 1;
            });
            return;
        }
        this.pickUpMemoRegisterSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            var aPick = _this.pickUpMemo[a.id];
            var bPick = _this.pickUpMemo[b.id];
            if (!aPick && !bPick) {
                return 0;
            }
            if (aPick === bPick) {
                return 0;
            }
            if (!aPick || !aPick.registerDate || (bPick && aPick.registerDate < bPick.registerDate)) {
                return -1;
            }
            return 1;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerIs2ndMatchSort = function () {
        if (this.is2ndMatchSortStatus === 'asc') {
            this.is2ndMatchSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.is2ndMatch || a.dynamicData.is2ndMatch > b.dynamicData.is2ndMatch) {
                    return -1;
                }
                if (a.dynamicData.is2ndMatch < b.dynamicData.is2ndMatch) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.is2ndMatchSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.is2ndMatch || a.dynamicData.is2ndMatch < b.dynamicData.is2ndMatch) {
                return -1;
            }
            if (a.dynamicData.is2ndMatch > b.dynamicData.is2ndMatch) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerCheck5IntroduceSort = function () {
        if (this.check5IntroduceSortStatus === 'asc') {
            this.check5IntroduceSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.check5Introduce || a.dynamicData.check5Introduce > b.dynamicData.check5Introduce) {
                    return -1;
                }
                if (a.dynamicData.check5Introduce < b.dynamicData.check5Introduce) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.check5IntroduceSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.check5Introduce || a.dynamicData.check5Introduce < b.dynamicData.check5Introduce) {
                return -1;
            }
            if (a.dynamicData.check5Introduce > b.dynamicData.check5Introduce) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerCheck5ApplySort = function () {
        if (this.check5ApplySortStatus === 'asc') {
            this.check5ApplySortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.check5Apply || a.dynamicData.check5Apply > b.dynamicData.check5Apply) {
                    return -1;
                }
                if (a.dynamicData.check5Apply < b.dynamicData.check5Apply) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.check5ApplySortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.check5Apply || a.dynamicData.check5Apply < b.dynamicData.check5Apply) {
                return -1;
            }
            if (a.dynamicData.check5Apply > b.dynamicData.check5Apply) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerMypageLoggedInAtSort = function () {
        if (this.mypageLoggedInAtSortStatus === 'asc') {
            this.mypageLoggedInAtSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.mypageLoggedInAt || a.mypageLoggedInAt > b.mypageLoggedInAt) {
                    return -1;
                }
                if (a.mypageLoggedInAt < b.mypageLoggedInAt) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.mypageLoggedInAtSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.mypageLoggedInAt || a.mypageLoggedInAt < b.mypageLoggedInAt) {
                return -1;
            }
            if (a.mypageLoggedInAt > b.mypageLoggedInAt) {
                return 1;
            }
            return 0;
        });
    };
    GlobalCustomerHeaderComponent.prototype.handlerDocumentSubmissionStatusSort = function () {
        if (this.documentSubmissionStatusSortStatus === 'asc') {
            this.documentSubmissionStatusSortStatus = 'desc';
            this.customers.sort(function (a, b) {
                if (!b.dynamicData.documentSubmissionStatus || a.dynamicData.documentSubmissionStatus > b.dynamicData.documentSubmissionStatus) {
                    return -1;
                }
                if (a.dynamicData.documentSubmissionStatus < b.dynamicData.documentSubmissionStatus) {
                    return 1;
                }
                return 0;
            });
            return;
        }
        this.documentSubmissionStatusSortStatus = 'asc';
        this.customers.sort(function (a, b) {
            if (!a.dynamicData.documentSubmissionStatus || a.dynamicData.documentSubmissionStatus < b.dynamicData.documentSubmissionStatus) {
                return -1;
            }
            if (a.dynamicData.documentSubmissionStatus > b.dynamicData.documentSubmissionStatus) {
                return 1;
            }
            return 0;
        });
    };
    return GlobalCustomerHeaderComponent;
}());
export { GlobalCustomerHeaderComponent };
