<div class="cheader-menu">
  <button class="cheader-menu__modify" (click)="toggleCustomer()">
    <span *ngIf="!isCustomer">
      顧客管理表を開く
    </span>
    <span *ngIf="isCustomer">
      顧客管理表を閉じる（更新）
    </span>
    <span
      class="cheader-menu__modify__arrow"
      [class.cheader-menu__modify__arrow--down]="!isCustomer"
      [class.cheader-menu__modify__arrow--up]="isCustomer"
    ></span>
  </button>
</div>

<div
  class="cheaderc"
  *ngIf="isCustomer"
>
  <ng-content
    *ngTemplateOutlet="
      table_template;
      context: { collection: customers, total: customer_total }
    "
  >
  </ng-content>
</div>

<ng-template #headerTemplate>
  <thead>
    <tr>
      <th class="cheaderc_table__th cheaderc_table__th__check5">稼働化5点チェック</th>
      <th class="cheaderc_table__th cheaderc_table__th__active">
        稼働
        <span
          *ngIf="activeSortStatus === 'asc'"
          (click)="handlerActiveSort()"
        >
          <img class="cheaderc_table__th__active__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="activeSortStatus === 'desc'"
          (click)="handlerActiveSort()"
        >
          <img class="cheaderc_table__th__active__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__progress">
        進捗
        <span
          *ngIf="progressSortStatus === 'asc'"
          (click)="handlerProgressSort()"
        >
          <img class="cheaderc_table__th__progress__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="progressSortStatus === 'desc'"
          (click)="handlerProgressSort()"
        >
          <img class="cheaderc_table__th__progress__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__customer">
        求職者
        <span
          *ngIf="graduateYearSortStatus === 'asc'"
          (click)="handlerGraduateYearSort()"
        >
          <img class="cheaderc_table__th__customer__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="graduateYearSortStatus === 'desc'"
          (click)="handlerGraduateYearSort()"
        >
          <img class="cheaderc_table__th__customer__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__interview">
        カウンセリング日
        <span
          *ngIf="interviewSortStatus === 'asc'"
          (click)="handlerInterviewSort()"
        >
          <img class="cheaderc_table__th__interview__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="interviewSortStatus === 'desc'"
          (click)="handlerInterviewSort()"
        >
          <img class="cheaderc_table__th__interview__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__sf">
        SF
        <span
          *ngIf="sfSortStatus === 'asc'"
          (click)="handlerSfSort()"
        >
          <img class="cheaderc_table__th__sf__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="sfSortStatus === 'desc'"
          (click)="handlerSfSort()"
        >
          <img class="cheaderc_table__th__sf__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__enterprise">ご支援想定企業</th>
      <th class="cheaderc_table__th cheaderc_table__th__enterprise-other">他候補企業</th>
      <th class="cheaderc_table__th cheaderc_table__th__actions">&#8195;&#8195;</th>
      <th class="cheaderc_table__th cheaderc_table__th__memo-now">メモ</th>
      <th class="cheaderc_table__th cheaderc_table__th__memo-wish">志望順位</th>
      <th class="cheaderc_table__th cheaderc_table__th__memo-next">ネクストアクション</th>
      <th class="cheaderc_table__th cheaderc_table__th__last-contact">
        コンタクト
        <span
          *ngIf="pickUpMemoRegisterSortStatus === 'asc'"
          (click)="handlerPickUpMemoRegisterSort()"
        >
          <img class="cheaderc_table__th__second-match__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="pickUpMemoRegisterSortStatus === 'desc'"
          (click)="handlerPickUpMemoRegisterSort()"
        >
          <img class="cheaderc_table__th__second-match__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__second-match">
        セカマ
        <span
          *ngIf="is2ndMatchSortStatus === 'asc'"
          (click)="handlerIs2ndMatchSort()"
        >
          <img class="cheaderc_table__th__second-match__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="is2ndMatchSortStatus === 'desc'"
          (click)="handlerIs2ndMatchSort()"
        >
          <img class="cheaderc_table__th__second-match__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__introduce">
        最終求人紹介
        <span
          *ngIf="check5IntroduceSortStatus === 'asc'"
          (click)="handlerCheck5IntroduceSort()"
        >
          <img class="cheaderc_table__th__introduce__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="check5IntroduceSortStatus === 'desc'"
          (click)="handlerCheck5IntroduceSort()"
        >
          <img class="cheaderc_table__th__introduce__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__apply">
        最終応募
        <span
          *ngIf="check5ApplySortStatus === 'asc'"
          (click)="handlerCheck5ApplySort()"
        >
          <img class="cheaderc_table__th__apply__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="check5ApplySortStatus === 'desc'"
          (click)="handlerCheck5ApplySort()"
        >
          <img class="cheaderc_table__th__apply__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__mypage">
        ログイン
        <span
          *ngIf="mypageLoggedInAtSortStatus === 'asc'"
          (click)="handlerMypageLoggedInAtSort()"
        >
          <img class="cheaderc_table__th__mypage__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="mypageLoggedInAtSortStatus === 'desc'"
          (click)="handlerMypageLoggedInAtSort()"
        >
          <img class="cheaderc_table__th__mypage__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
      <th class="cheaderc_table__th cheaderc_table__th__document">
        書類提出
        <span
          *ngIf="documentSubmissionStatusSortStatus === 'asc'"
          (click)="handlerDocumentSubmissionStatusSort()"
        >
          <img class="cheaderc_table__th__document__img" src="/assets/icons/arrow-up.png" />
        </span>
        <span
          *ngIf="documentSubmissionStatusSortStatus === 'desc'"
          (click)="handlerDocumentSubmissionStatusSort()"
        >
          <img class="cheaderc_table__th__document__img" src="/assets/icons/arrow-down.png" />
        </span>
      </th>
    </tr>
  </thead>
</ng-template>

<ng-template
  #table_template
  let-collection="collection"
  let-total="total"
>
  <table class="cheaderc_table" *ngIf="total">
    <ng-content *ngTemplateOutlet="headerTemplate"></ng-content>
    <div class="loading" *ngIf="loadingCustomer">
      <svg class="loading__spinner" viewBox="0 0 50 50">
        <circle class="loading__spinner__path" cx="25" cy="25" r="20"></circle>
      </svg>
    </div>
    <tbody>
      <tr
        *ngFor="let data of collection"
      >
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_check5">
            <span
              class="cheaderc_table__td_check5__flag"
              [class.cheaderc_table__td_check5__flag--blue]="handlerCheck5SecondMatch(data?.dynamicData?.is2ndMatch)"
              [class.cheaderc_table__td_check5__flag--dark]="!handlerCheck5SecondMatch(data?.dynamicData?.is2ndMatch)"
            >
              セカマ設定
            </span>
            <span
              class="cheaderc_table__td_check5__flag"
              [class.cheaderc_table__td_check5__flag--blue]="handlerCheck5DocumentSubmissionStatus(data?.dynamicData?.documentSubmissionStatus)"
              [class.cheaderc_table__td_check5__flag--dark]="!handlerCheck5DocumentSubmissionStatus(data?.dynamicData?.documentSubmissionStatus)"
            >
              書類回収
            </span>
          </div>
          <div class="cheaderc_table__td_check5" style="margin-top: 4px;">
            <span
              class="cheaderc_table__td_check5__flag"
              [class.cheaderc_table__td_check5__flag--blue]="handlerCheck5PickupMemoStatus(data.id)"
              [class.cheaderc_table__td_check5__flag--dark]="!handlerCheck5PickupMemoStatus(data.id)"
            >
              直近接点
            </span>
            <span
              class="cheaderc_table__td_check5__flag-mini"
              [class.cheaderc_table__td_check5__flag-mini--blue]="data?.dynamicData?.check5Introduce"
              [class.cheaderc_table__td_check5__flag-mini--dark]="!data?.dynamicData?.check5Introduce"
            >
              紹介
            </span>
            <span
              class="cheaderc_table__td_check5__flag-mini"
              [class.cheaderc_table__td_check5__flag-mini--blue]="data?.dynamicData?.check5Apply"
              [class.cheaderc_table__td_check5__flag-mini--dark]="!data?.dynamicData?.check5Apply"
            >
              応募
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_active">
            <span class="cheaderc_table__td_active__data">
              {{ data.activeCount }}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_progress">
            <span class="cheaderc_table__td_progress__data">
              {{ data.progressCount }}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_customer">
            <div class="cheaderc_table__td_customer__above">
              <span class="cheaderc_table__td_customer__above__frontid">
                {{data?.frontId}}
              </span>
              <span
                *ngIf="data?.dynamicData?.academicFieldType"
                class="cheaderc_table__td_customer__above__flag"
                [class.cheaderc_table__td_customer__above__flag--pink]="
                  data?.dynamicData?.academicFieldType?.includes('文')
                "
                [class.cheaderc_table__td_customer__above__flag--blue]="
                  data?.dynamicData?.academicFieldType?.includes('理')
                "
                >{{
                  data?.dynamicData.academicFieldType.includes("文") ? "文" : "理"
                }}</span
              >
              <span
                *ngIf="data?.dynamicData?.academicHistory1?.graduateYear"
                class="cheaderc_table__td_customer__above__flagg cheaderc_table__td_customer__above__flagg--blue"
              >
                {{handlerGraduateYearView(data.dynamicData.academicHistory1.graduateYear) }} 卒
              </span>
              <span class="cheaderc_table__td_customer__above__rank">
                |&nbsp;{{data?.dynamicData.universityLevel | slice: 0 : 1}}&nbsp;|
              </span>
            </div>
            <div
              class="cheaderc_table__td_customer__under"
              (click)="onItemSelect(data)"
            >
              {{data?.fullName}}
            </div>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_date__above">
            <span
              *ngIf="data?.dynamicData?.interviewDate"
              class="cheaderc_table__td_date"
            >
              {{data?.dynamicData?.interviewDate | slice: 0 : 4}}
            </span>
          </div>
          <div class="cheaderc_table__td_date__under">
            <span
              *ngIf="data?.dynamicData?.interviewDate"
              class="cheaderc_table__td_date"
            >
              {{handlerDateMonthDay(data.dynamicData.interviewDate)}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_sf">
            <span
              *ngIf="data?.dynamicData?.rank"
              class="cheaderc_table__td_sf--flag cheaderc_table__td_sf--flag--{{ data.dynamicData.rank }}"
            >{{data.dynamicData.rank}}</span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_enterprise">
            <div class="cheaderc_table__td_enterprise__above">
              <select
                class="cheaderc_table__td_enterprise__above__select select select--bg-gray select--small"
                [(ngModel)]="expectedCompany[data.id]"
                (change)="handlerChangeExpectedCompany(data.id, expectedCompany[data.id])"
              >
                <option
                  *ngFor="let expectedCompany of expectedCompanies[data.id]"
                  [value]="expectedCompany.p_id"
                  >{{ expectedCompany.e_name }}</option
                >
              </select>
            </div>
            <div class="cheaderc_table__td_enterprise__under">
              <span
                *ngIf="expectedData[data.id]"
                class="cheaderc_table__td_enterprise__under__flagg"
                [class.cheaderc_table__td_enterprise__under__flagg--ag]="expectedData[data.id].p_type === 0"
                [class.cheaderc_table__td_enterprise__under__flagg--plus]="expectedData[data.id].p_type === 1"
              >{{handlerExpectedCompanyTypeView(expectedData[data.id].p_type)}}</span>
              
              <span *ngIf="expectedData[data.id]" class="cheaderc_table__td_enterprise__under__user">
                {{ expectedData[data.id].u_name | truncate: 5 }}
              </span>
              <span *ngIf="expectedData[data.id]" class="cheaderc_table__td_enterprise__under__status">
                {{ handlerProgressStatusView(expectedData[data.id]) | truncate: 6 }}
              </span>
              <span *ngIf="expectedData[data.id] && expectedData[data.id].p_seminar_at" class="cheaderc_table__td_enterprise__under__date">
                {{ expectedData[data.id].p_seminar_at | date: "yyyy/MM/dd" }}
              </span>
            </div>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div
            *ngFor="let expectedCompany of expectedCompaniesWithoutExpected[data.id]"
            class="cheaderc_table__td_enterprise-other"
          >
            <div class="cheaderc_table__td_enterprise-other__line">
              <span *ngIf="expectedCompany.e_name" class="cheaderc_table__td_enterprise-other__line__name">
                {{ handlerCompanyNameReplace(expectedCompany.e_name) | truncate: 7 }}
              </span>
              <span class="cheaderc_table__td_enterprise-other__line__status">
                {{ handlerProgressStatusView(expectedCompany) | truncate: 7 }}
              </span>
            </div>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_actions">
            <div class="cheaderc_table__td_actions__above">
              <a
                class="cheaderc_table__td_actions__chat"
                (click)="onItemSelect(data)"
              >
                <div class="cheaderc_table__td_actions__icon">
                  <img class="cheaderc_table__td_actions__img" src="/assets/images/phone.png" />
                </div>
              </a>
              <a
                class="cheaderc_table__td_actions__chat"
                (click)="
                  true
                    ? openChat(data?.dynamicData?.lineId)
                    : ''
                "
              >
                <div class="cheaderc_table__td_actions__icon">
                  <img class="cheaderc_table__td_actions__img" src="/assets/images/chat.png" />
                </div>
              </a>
            </div>
            <div class="cheaderc_table__td_actions__under">
              <a
                class="cheaderc_table__td_actions__chat"
                (click)="
                  data.dynamicData?.emailMain?.email &&
                  !data.dynamicData?.emailMain?.emailAvailable
                    ? openMailSend(sendConfig, data)
                    : ''
                "
              >
                <div class="cheaderc_table__td_actions__icon">
                  <img class="cheaderc_table__td_actions__img" src="/assets/images/mail.png" />
                </div>
              </a>
              <a
                class="cheaderc_table__td_actions__chat"
                (click)="onItemSelectAndMemo(data)"
              >
                <div class="cheaderc_table__td_actions__icon">
                  <img class="cheaderc_table__td_actions__img" src="/assets/images/note.png" />
                </div>
              </a>
            </div>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_memos">
            <span 
              *ngIf="pickUpMemo[data.id]"
              class="cheaderc_table__td_memos__text"
            >
              {{handlerSituationMemoEtcView(pickUpMemo[data.id])}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_memos">
            <span 
              *ngIf="pickUpMemo[data.id]"
              class="cheaderc_table__td_memos__text"
            >
              {{handlerSituationMemoWishView(pickUpMemo[data.id])}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_memos">
            <span 
              *ngIf="pickUpMemo[data.id]"
              class="cheaderc_table__td_memos__text"
            >
              {{handlerSituationMemoNextView(pickUpMemo[data.id])}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_date__above">
            <span
              *ngIf="pickUpMemo[data.id]"
              class="cheaderc_table__td_date"
            >
              {{pickUpMemo[data.id].registerDate | slice: 0 : 4}}
            </span>
          </div>
          <div class="cheaderc_table__td_date__under">
            <span
              *ngIf="pickUpMemo[data.id]"
              class="cheaderc_table__td_date"
            >
              {{handlerDateMonthDay(pickUpMemo[data.id].registerDate)}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div
            *ngIf="data?.dynamicData?.is2ndMatch"
            class="cheaderc_table__td_text"
          >
            {{data.dynamicData.is2ndMatch}}
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_date__above">
            <span
              *ngIf="data?.dynamicData?.check5Introduce"
              class="cheaderc_table__td_date"
            >
              {{data.dynamicData.check5Introduce | slice: 0 : 4}}
            </span>
          </div>
          <div class="cheaderc_table__td_date__under">
            <span
              *ngIf="data?.dynamicData?.check5Introduce"
              class="cheaderc_table__td_date"
            >
              {{handlerDateMonthDay(data.dynamicData.check5Introduce)}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_date__above">
            <span
              *ngIf="data?.dynamicData?.check5Apply"
              class="cheaderc_table__td_date"
            >
              {{data.dynamicData.check5Apply | slice: 0 : 4}}
            </span>
          </div>
          <div class="cheaderc_table__td_date__under">
            <span
              *ngIf="data?.dynamicData?.check5Apply"
              class="cheaderc_table__td_date"
            >
              {{handlerDateMonthDay(data.dynamicData.check5Apply)}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div class="cheaderc_table__td_date__above">
            <span
              *ngIf="data?.mypageLoggedInAt"
              class="cheaderc_table__td_date"
            >
              {{data.mypageLoggedInAt | slice: 0 : 4}}
            </span>
          </div>
          <div class="cheaderc_table__td_date__under">
            <span
              *ngIf="data?.mypageLoggedInAt"
              class="cheaderc_table__td_date"
            >
              {{handlerDateMonthDay(data.mypageLoggedInAt)}}
            </span>
          </div>
        </td>
        <td class="cheaderc_table__td">
          <div
            [class.cheaderc_table__td_text]="data?.dynamicData?.documentSubmissionStatus === 'OM有'"
            [class.cheaderc_table__td_text-large]="data?.dynamicData?.documentSubmissionStatus === '有'"
            [class.cheaderc_table__td_text-small]="data?.dynamicData?.documentSubmissionStatus === 'キャリアノート'"
          >
            {{data?.dynamicData?.documentSubmissionStatus}}
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="cheaderc_table__td">
          合計: {{total}}件
        </td>
      </tr>
    </tfoot>
  </table>
</ng-template>

<ng-template
  #currencyCell
  let-value="currency"
  let-userId="userId"
  let-progressType="progressType"
  let-agentType="agentType"
  let-canceled="canceled"
>
  <td
    class="currency currency__clickable"
    [ngClass]="{ 'neg-value': value < 0, 'pos-value': value > 0 }"
    [routerLink]="['/sales/list']"
    [queryParams]="
      getSearchQueryParams(userId, progressType, agentType, canceled)
    "
  >
    {{ value | undef | number: "0.0-2" }}
  </td>
</ng-template>

<ng-template #totalCell let-value>
  <td
    class="currency"
    [ngClass]="{ 'neg-value': value < 0, 'pos-value': value > 0 }"
  >
    {{ value | undef | number: "0.0-2" }}
  </td>
</ng-template>
