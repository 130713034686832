/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-menu/global-menu.component.ngfactory";
import * as i3 from "./global-menu/global-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/auth.service";
import * as i6 from "../../services/api/job-api.service";
import * as i7 from "./global-header/global-header.component.ngfactory";
import * as i8 from "./global-header/global-header.component";
import * as i9 from "../../services/dialog.service";
import * as i10 from "./global-cheader/global-cheader.component.ngfactory";
import * as i11 from "./global-cheader/global-cheader.component";
import * as i12 from "@angular/common";
import * as i13 from "../../services/api/export-api.service";
import * as i14 from "../../services/api/sales-api.service";
import * as i15 from "../../pipes/safe-date.pipe";
import * as i16 from "../../services/api/student-api.service";
import * as i17 from "../../services/api/customer-api.service";
import * as i18 from "../../services/api/master-api.service";
import * as i19 from "../../services/api/user-api.service";
import * as i20 from "../../services/api/providers/user-team-injector.provider";
import * as i21 from "@angular/forms";
import * as i22 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-global-menu", [["class", "menu"]], null, null, null, i2.View_GlobalMenuComponent_0, i2.RenderType_GlobalMenuComponent)), i1.ɵdid(1, 4243456, null, 0, i3.GlobalMenuComponent, [i4.Router, i5.AuthService, i6.JobApiService], null, null)], null, null); }
function View_LayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-global-header", [["class", "header"]], null, null, null, i7.View_GlobalHeaderComponent_0, i7.RenderType_GlobalHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.GlobalHeaderComponent, [i5.AuthService, i9.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_LayoutComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-global-cheader", [["class", "cheader"]], null, null, null, i10.View_GlobalCustomerHeaderComponent_0, i10.RenderType_GlobalCustomerHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i11.GlobalCustomerHeaderComponent, [i4.Router, i4.ActivatedRoute, i12.Location, i13.ExportApiService, i14.SalesApiService, i9.DialogService, i15.SafeDatePipe, i5.AuthService, i16.StudentApiService, i17.CustomerApiService, i18.MasterApiService, i19.UserApiService, i20.UserTeamInjectorProvider, i21.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_2)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_3)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "content"]], [[2, "content--full", null]], null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.useLayout; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.useLayout; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.useLayout; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.useLayout; _ck(_v, 6, 0, currVal_3); }); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ag-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 49152, null, 0, i22.LayoutComponent, [], null, null)], null, null); }
var LayoutComponentNgFactory = i1.ɵccf("ag-layout", i22.LayoutComponent, View_LayoutComponent_Host_0, { pageInfo: "pageInfo" }, {}, ["*"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
