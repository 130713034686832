import { SituationMemo, User } from '@agent-ds/shared/interfaces';
import { FormMeta } from '@agent-ds/shared/models';
import {
  AuthService,
  CompanyApiService,
  DynamicFieldService,
  JobApiService,
  StudentApiService,
  UserApiService,
} from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { PopupControlComponent } from '../../popup-control/popup-control.component';

const DEFAULT_TRUNCATE_AT = 300;

@Component({
  selector: 'ag-memo',
  templateUrl: './memo.component.html',
  styleUrls: ['./memo.component.scss'],
})
export class MemoComponent implements OnInit, OnChanges {
  @Input() service: StudentApiService | CompanyApiService | JobApiService;
  @Input() referenceId: number;
  @Input() isCreateInProgress = false;
  @Input() memo: SituationMemo;
  @Output() createCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteSuccess: EventEmitter<void> = new EventEmitter<void>();
  isEditInProgress = false;
  oldText = '';
  truncateAt: number | null = DEFAULT_TRUNCATE_AT;

  metadata: FormMeta = {
    groups: [
      {
        class: 'ou',
        rows: [
          {
            fields: [
              {
                type: 'date',
                name: 'registerDate',
                class: 'quarter tall',
                validators: { required: true },
              },
              this.dynamicFieldService.getFormRows(
                {
                  fieldName: 'userId',
                  fieldType: 'user',
                  validationStyle: { required: true },
                },
                null,
                'quarter',
              )[0].fields[0],
            ],
          },
          ...this.dynamicFieldService.getFormRows({
            fieldName: 'innerMemo',
            fieldType: 'text',
            displayType: 'textarea',
          }),
        ],
      },
    ],
  };

  model: SituationMemo = { registerDate: new Date() };

  constructor(
    private masterApiService: MasterApiService,
    private dynamicFieldService: DynamicFieldService,
    private userApiService: UserApiService,
    private authService: AuthService,
  ) {}

  get isOpen(): boolean {
    return !this.truncateAt;
  }

  ngOnInit() {
    if (this.isCreateInProgress) {
      this.memo = {
        registerDate: new Date().toISOString().substr(0, 10),
        innerMemo: '',
        situationMemo: `◾️志望状況

◾️ネクストアクション

◾️その他メモ`,
        userId: this.authService.loginUser.id,
      };
    }
    this.model = { ...this.memo };
    delete this.model.user;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['memo'] && this.memo) {
      this.model = { ...this.memo };
      delete this.model.user;
    }
    if (changes['service']) {
      this.metadata.groups[0].rows[1].fields[0].name =
        this.service instanceof CompanyApiService ? 'innerMemo' : this.service instanceof StudentApiService ? 'situationMemo' : 'memo';
    }
  }

  findUserTeam(userId: number): Observable<{ userName: string; teamName: string }> {
    return this.userApiService.getAll().pipe(
      mergeMap((users) => {
        const user = users.find((u) => u.id === userId);
        if (user) {
          return this.masterApiService.getTeams().pipe(
            map((teams) => {
              const team = teams.find((t) => t.id === user.teamId);
              if (team) {
                return { userName: user.name, teamName: team.name };
              } else {
                return { userName: user.name, teamName: '' };
              }
            }),
          );
        }
        return null;
      }),
    );
  }

  toggleText(): void {
    this.truncateAt = this.truncateAt ? null : DEFAULT_TRUNCATE_AT;
  }

  edit(): void {
    this.isEditInProgress = true;
  }

  cancelEdit(): void {
    this.model = { ...this.memo };
    this.isEditInProgress = false;
  }

  cancelCreate(): void {
    this.isCreateInProgress = false;
    this.createCanceled.emit();
  }

  create(): void {
    this.service.addMemo(this.referenceId, this.model).subscribe(() => {
      this.cancelCreate();
      this.saveSuccess.emit();
    });
  }

  update(): void {
    this.service.updateMemo(this.referenceId, this.model).subscribe(() => {
      this.cancelEdit();
      this.saveSuccess.emit();
    });
  }

  delete(): void {
    PopupControlComponent.instance.open({
      title: '削除確認',
      content: '削除されたデータを元に戻すことはできません。\nデータを削除してもよろしいですか？',
      confirmText: 'OK',
      cancelText: 'キャンセル',
      confirmCallback: () => {
        return this.service.deleteMemo(this.referenceId, this.memo.id).pipe(
          tap(() => {
            this.deleteSuccess.emit();
          }),
        );
      },
    });
  }
}
